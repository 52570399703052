import { getUserRoles, getEmployeeDashboards } from "../utils/Functions";
import {
  Users as UsersIcon,
  UserPlus as UserPlusIcon,
  Briefcase as BriefcaseIcon,
  DollarSign as DollarSignIcon,
  Book as BookIcon,
  Columns as ColumnsIcon,
  FileText as FileTextIcon,
  Package as PackageIcon,
  Layers as LayersIcon,
  Edit2 as PenIcon,
  ThumbsUp as ThumbsUpIcon,
  Upload as UploadIcon,
  HelpCircle as HelpCircleIcon,
  Clipboard as ClipboardIcon,
  CheckSquare as CheckSquareIcon,
  Video as VideoIcon,
  Airplay as AirplayIcon,
  AlertCircle as ContactIcon,
  Gift as GiftIcon,
  Archive as ArchiveIcon,
  Phone as PhoneIcon,
  CheckCircle as TaskIcon,
  FileMinus as InvoiceTcon,
} from "react-feather";
import VideoLibraryIcon from "@material-ui/icons/VideoLibraryOutlined";

interface subItemsFormat {
  id: string;
  href: string;
  icon: any;
  title: string;
}

interface subItemsArray extends Array<subItemsFormat> {}

interface itemFormat {
  id: string;
  title: string;
  subItems: subItemsArray;
}

interface itemsArray extends Array<itemFormat> {}

//Always add a new dashboard to the array below first
export const dashboards = [
  {
    id: "modules",
    title: "Modules",
  },
  {
    id: "subscriptions",
    title: "Subscriptions",
  },
  {
    id: "notes",
    title: "Notes",
  },
  {
    id: "notelibrary",
    title: "Notes Library",
  },
  {
    id: "blogs",
    title: "Blogs",
  },
  {
    id: "popUps",
    title: "PopUps",
  },
  {
    id: "offerAds",
    title: "offerAds",
  },
  {
    id: "contact",
    title: "Contact",
  },
  {
    id: "adCategory",
    title: "Ad Categories",
  },
  {
    id: "answers",
    title: "Answers",
  },
  {
    id: "mainsTestSeries",
    title: "TestSeries",
  },
  {
    id: "questions",
    title: "Questions",
  },
  {
    id: "evaluators",
    title: "Evaluators",
  },
  {
    id: "manual-upload",
    title: "Manual Upload",
  },
  {
    id: "customers",
    title: "Customers",
  },
  {
    id: "customer-subscriptions",
    title: "Customer Subscriptions",
  },
  {
    id: "payments",
    title: "Payments",
  },
  {
    id: "members",
    title: "Members",
  },
  {
    id: "videos",
    title: "Videos",
  },
  {
    id: "videosLibrary",
    title: "Videos Library",
  },
  {
    id: "booksOrders",
    title: "Books Orders",
  },
  {
    id: "onBoarding",
    title: "On Boarding",
  },
  {
    id: "enquiry",
    title: "Enquiry",
  },
  {
    id: "tasks",
    title: "Tasks",
  },
  {
    id: "invoices",
    title: "Invoices",
  },
  {
    id: "partner-subscriptions",
    title: "Partner Subscriptions",
  },
];

const getDashboards = () => {
  if (getUserRoles?.isAdmin) return adminDashboards;
  else if (getUserRoles?.isEvaluator) return evaluatorDashboards;
  else if (getUserRoles?.isEmployee) return employeeDashboards();
  else if (getUserRoles?.isPartner) return partnerDashboards;
};

const adminDashboards: itemsArray = [
  {
    id: "products",
    title: "Products & Services",
    subItems: [
      {
        id: "modules",
        href: "/modules",
        icon: ColumnsIcon,
        title: "Modules",
      },
      {
        id: "subscriptions",
        href: "/subscriptions",
        icon: PackageIcon,
        title: "Subscriptions",
      },
      {
        id: "notes",
        href: "/notes",
        icon: FileTextIcon,
        title: "Notes",
      },
      {
        id: "notelibrary",
        href: "/notelibrary",
        icon: BookIcon,
        title: "Notes Library",
      },
      {
        id: "blogs",
        href: "/blogs",
        icon: PenIcon,
        title: "Blogs",
      },
      {
        id: "videos",
        href: "/videos",
        icon: VideoIcon,
        title: "Videos",
      },
      {
        id: "videosLibrary",
        href: "/videoslibrary",
        icon: VideoLibraryIcon,
        title: "Videos Library",
      },
      {
        id: "adCategory",
        href: "/adCategory",
        icon: ThumbsUpIcon,
        title: "Ad Categories",
      },
    ],
  },
  {
    id: "mains",
    title: "Mains TestSeries",
    subItems: [
      {
        id: "answers",
        href: "/answers",
        icon: LayersIcon,
        title: "Answers",
      },
      {
        id: "mainsTestSeries",
        href: "/mainsTestSeries",
        icon: ClipboardIcon,
        title: "TestSeries",
      },
      {
        id: "questions",
        href: "/questions",
        icon: HelpCircleIcon,
        title: "Questions",
      },
      {
        id: "evaluators",
        href: "/evaluators",
        icon: CheckSquareIcon,
        title: "Evaluators",
      },
      {
        id: "manual-upload",
        href: "/manual-upload",
        icon: UploadIcon,
        title: "Manual Upload",
      },
    ],
  },
  {
    id: "management",
    title: "Management",
    subItems: [
      {
        id: "customers",
        href: "/customers",
        icon: UsersIcon,
        title: "Customers",
      },
      {
        id: "customer-subscriptions",
        href: "/customer-subscriptions",
        icon: UserPlusIcon,
        title: "Customer Subscriptions",
      },
      {
        id: "payments",
        href: "/payments",
        icon: DollarSignIcon,
        title: "Payments",
      },
      {
        id: "popUps",
        href: "/popUps",
        icon: AirplayIcon,
        title: "PopUps",
      },
      {
        id: "offerAds",
        href: "/offerAds",
        icon: GiftIcon,
        title: "OfferAds",
      },
      {
        id: "contact",
        href: "/contact",
        icon: ContactIcon,
        title: "Contact",
      },
      {
        id: "booksOrders",
        href: "/booksOrders",
        icon: ArchiveIcon,
        title: "Books Orders",
      },
      {
        id: "enquiry",
        href: "/enquiry",
        icon: PhoneIcon,
        title: "Enquiries",
      },
      {
        id: "tasks",
        href: "/tasks",
        icon: TaskIcon,
        title: "Tasks",
      },
      {
        id: "invoices",
        href: "/invoices",
        icon: InvoiceTcon,
        title: "Invoices",
      },
    ],
  },
  {
    id: "administration",
    title: "Administration",
    subItems: [
      {
        id: "members",
        href: "/members",
        icon: BriefcaseIcon,
        title: "Members",
      },
      {
        id: "onBoarding",
        href: "/onBoarding",
        icon: BriefcaseIcon,
        title: "On Boarding",
      },
    ],
  },
].filter((value) => Object.keys(value).length !== 0);

const evaluatorDashboards: itemsArray = [
  {
    id: "mains",
    title: "Mains TestSeries",
    subItems: [
      {
        id: "answers/evaluator",
        href: "/answers/evaluator",
        icon: LayersIcon,
        title: "Answers",
      },
    ],
  },
];

const employeeDashboards = () => {
  let menuItems: itemsArray = [];
  adminDashboards.forEach((item) => {
    const tempItem = { ...item };
    tempItem.subItems = [];
    console.log(getEmployeeDashboards);
    item.subItems?.forEach((sub) => {
      if (getEmployeeDashboards.indexOf(sub.id) > -1)
        tempItem.subItems.push(sub);
    });
    if (tempItem.subItems?.length) menuItems.push(tempItem);
  });
  return menuItems;
};

const partnerDashboards = [
  {
    id: "faculty-subscriptions",
    title: "Dashboard",
    subItems: [
      {
        id: "faculty-subscriptions",
        href: "/faculty-subscriptions",
        icon: LayersIcon,
        title: "Subscriptions",
      },
    ],
  },
];

//Add a new dashboard to one or multiple roles below
export const menuItems: itemsArray | undefined = getDashboards();
