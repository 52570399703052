import React, { useEffect, useState } from "react";
import Table from "../../components/Commons/Table";
import useSWR from "swr";
import {
  errorToast,
  fetcher,
  getUserRoles,
  loadingToast,
  successToast,
} from "../../utils/Functions";
import { staticOptions } from "../../utils/Constants";
import LinearLoading from "../../components/Commons/LinearLoading";
import TimeField from "../../components/Commons/TimeField";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { ShoppingCart } from "react-feather";
import Filter from "../../components/Commons/Filter";
import toast, { Toaster } from "react-hot-toast";

const PartnerSubscriptions = () => {
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [queryString, setQueryString] = useState<string>(``);

  const { data } = useSWR(
    getUserRoles.isPartner
      ? `/userSubscription/partner?limit=${limit}&page=${page}${queryString}`
      : null,
    fetcher,
    staticOptions
  );

  useEffect(() => {
    if (!Boolean(data) || Boolean(data?.error)) return;
    data?.result?.docs?.forEach((item) => {
      item.startDate = timeField(item.startDate);
    });
  }, [data]);

  function timeField(date: string) {
    return <TimeField date={date} showTime={true} />;
  }

  // const filters = [
  //   {
  //     name: "Email",
  //     filterType: "textField",
  //     options: [],
  //     defaultValue: "",
  //     param: "email",
  //   },
  //   {
  //     name: "Subscription Name",
  //     filterType: "dropDown",
  //     options: subscriptions?.result?.map((item) => {
  //       return { label: item.name, id: item._id };
  //     }),
  //     defaultValue: { label: "", id: "" },
  //     param: "subscriptionTypeId",
  //   },
  // ];
  return (
    <>
      {/* <Filter
            filters={filters}
            setQueryString={(query) => {
              setQueryString(query);
              setPage(1);
            }}
          /> */}
      {!!!data?.result ? (
        <LinearLoading />
      ) : (
        <>
          <Table
            data={data?.result?.docs}
            dataOrder={[
              "email",
              "subscriptionName",
              // "subscriptionTypeId",
              "amount",
              "startDate",
              // "note",
              // "createdBy",
            ]}
            uniqueId={"_id"}
            // enableDelete={true}
            // apiEndpoint={"userSubscription"}
            setLimit={setLimit}
            setPage={setPage}
            count={data?.result?.totalDocs}
            currentPage={data?.result?.page}
            pagination={true}
          />
        </>
      )}
      <Toaster />
    </>
  );
};

export default PartnerSubscriptions;
